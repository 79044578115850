import React from 'react';
import styled from 'styled-components';

const StyledAboutMast = styled.div`
	padding: 2rem 3rem 3rem 6rem;
	background-image: url(/img/FLR_About_Light-Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	.left {
		width: 55%;
		display: flex;
		max-width: 550px;
		align-items: center;
		h1 {
			color: ${(p) => p.theme.color.red};
			margin-bottom: 1rem;
		}
		p {
			font-size: 1.3rem;
			line-height: 1.7rem;
		}
	}
	.right {
		width: 42%;
		max-width: 400px;
		margin-left: auto;
	}
	@media all and (min-width: 1200px) {
		padding: 2rem 3rem 3rem 12rem;
		.right {
			max-width: none;
			width: 40%;
		}
	}
	@media all and (min-width: 1400px) {
		padding: 2rem 3rem 3rem 12rem;
		.right {
			margin-right: 10%;
			max-width: 500px;
		}
	}
	@media all and (min-width: 1600px) {
		padding-left: 20rem;
		h1 {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		h2 {
			font-size: 2rem;
		}
		p {
			font-size: 1.3rem !important;
		}
	}
	@media all and (max-width: 1000px) {
		padding: 2rem 3rem 3rem 5rem;
		h1 {
			font-size: 2rem;
		}
	}
	@media all and (max-width: 800px) {
		padding: 2rem 2rem 3rem 4rem;
	}
	@media all and (max-width: 668px) {
		flex-direction: column;
		padding: 2rem 0 3rem;
		.left,
		.right {
			width: 70%;
			margin: 0 auto;
		}
	}
`;

const AboutMast = ({ data }) => {
	return (
		<StyledAboutMast>
			<div className="left">
				<div className="text-container">
					<h1>{data.mastHeading}</h1>
					<p>{data.mastCopy}</p>
				</div>
			</div>
			<div className="right">
				<img src="/img/FLR_Puck.png" alt="first line rinks puck" />
			</div>
		</StyledAboutMast>
	);
};

export default AboutMast;
