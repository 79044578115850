import React from 'react';

import AboutMast from '../components/about/masthead';
import AboutCopy from '../components/about/copy';
import AboutTeam from '../components/about/team';
import RinksCTA from '../components/rinks-cta';
import Bar from '../components/edgebar';

const AboutTemplate = ({ copy }) => {
	return (
		<>
			<AboutMast data={copy} />
			<Bar
				color="red"
				left="0"
				right="auto"
				top="-250px"
				width="auto"
				height="500px"
			/>
			<AboutCopy data={copy} />
			<Bar
				color="blue"
				left="auto"
				right="-5px"
				top="-20px"
				width="300px"
				height="auto"
			/>
			<AboutTeam data={copy} />
			<Bar
				color="red"
				left="auto"
				right="0"
				top="-200px"
				width="auto"
				height="330px"
			/>
			<RinksCTA />
		</>
	);
};

export default AboutTemplate;
