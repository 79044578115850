import React from 'react';
import styled from 'styled-components';

const StyledAboutCopy = styled.div`
	padding: 8rem 6rem;
	background-image: url(/img/FLR_About_Owners.jpg);
	background-position: right bottom;
	background-size: auto 90%;
	background-repeat: no-repeat;
	h2 {
		color: ${(p) => p.theme.color.red};
		margin-bottom: 10px;
	}
	p {
		width: 55%;
	}
	@media all and (min-width: 1400px) {
		padding: 12rem;
	}
	@media all and (min-width: 1600px) {
		padding-left: 20rem;
		h2 {
			font-size: 2rem;
		}
		p {
			font-size: 1.3rem;
			line-height: 2rem;
		}
	}
	@media all and (max-width: 1000px) {
		padding: 5rem 5rem;
	}
	@media all and (max-width: 900px) {
		background-size: auto 85%;
		background-position: 110% bottom;
	}
	@media all and (max-width: 800px) {
		background-size: auto 90%;
		padding: 3rem 4rem 4rem;
	}
	@media all and (max-width: 721px) {
		background-size: auto 85%;
		background-position: 120% bottom;
	}
	@media all and (max-width: 668px) {
		padding: 3rem 0rem 26rem;
		background-size: auto 375px;
		background-position: center bottom;
		h2,
		p {
			width: 70%;
			margin: 0 auto 10px;
		}
	}
	@media all and (max-width: 450px) {
		background-size: 100% auto;
	}
	@media all and (max-width: 380px) {
		padding: 3rem 0rem 23rem;
	}
`;

const AboutCopy = ({ data }) => {
	return (
		<StyledAboutCopy>
			<h2>{data.heading}</h2>
			<p>{data.copy}</p>
		</StyledAboutCopy>
	);
};

export default AboutCopy;
