import React from 'react';

import Layout from '../components/layout';
import AboutTemplate from '../templates/about-template';
import SEO from '../components/seo';

const copy = {
	mastHeading: 'WE BRING THE GAME WE LOVE TO YOUR BACKYARD',
	mastCopy: `First Line Rinks isn’t just about building great ice surfaces and
	boards, it’s about bringing the game we love to your backyard.`,
	copyHeading: 'ABOUT US',
	copy: `Founded in 2014, First Line Rinks has provided families and corporate businesses with the opportunity to have a portable ice rink system in the location of their choice. Whether it’s a backyard peewee ice rink, a synthetic system in your basement, or a large custom pro rink that’s over 200 feet long, you can trust First Line Rinks to deliver the best service and highest quality in the industry.`,
	teamHeading: 'THE TEAM',
	teamCopy:
		'Our team of dedicated pros have a combined experience of over 15 years in the ice rink industry, and we’ve installed over 120 rinks across Canada.',
	tyler: {
		name: 'TYLER AGOSTINI',
		title: 'Director of Operations',
		img: '/img/FLR_About_Tyler.png',
	},
	jacob: {
		name: 'JACOB MCKENDRY',
		title: 'Director of Sales',
		img: '/img/FLR_About_Jacob.png',
	},
};

const AboutPage = () => (
	<Layout>
		<SEO
			title="About"
			description="Our team of dedicated professionals have over 15 years of experience in the ice rink industry, with over 120 rinks across Canada. Let’s talk about your rink today!"
		/>
		<AboutTemplate copy={copy} />
	</Layout>
);

export default AboutPage;
