import React from 'react';
import styled from 'styled-components';

const StyledAboutTeam = styled.div`
	padding: 4rem 0 6rem;
	background-image: url(/img/FLR_About_Light-Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	h2 {
		color: ${(p) => p.theme.color.red};
	}
	.copy {
		width: 50%;
		margin: 1rem auto 0;
		font-size: 1.2rem;
		line-height: 1.5rem;
	}
	.people {
		display: flex;
		justify-content: space-around;
		width: 70%;
		margin: 3rem auto 0;
		.person {
			padding: 0 2rem;
			.name {
				font-weight: 700;
				margin-top: 1rem;
			}
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.people {
			width: 100%;
			padding: 0 5rem;
		}
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
		}
		p {
			font-size: 1.3rem !important;
			line-height: 2rem !important;
		}
	}
	@media all and (max-width: 1000px) {
		.copy {
			width: 70%;
		}
		.people {
			width: 90%;
			.person {
				padding: 0 1rem;
			}
		}
	}
	@media all and (max-width: 668px) {
		.copy {
			width: 70%;
		}
		.people {
			flex-direction: column;
			.person {
				width: 80%;
				margin: 0 auto;
				.name {
					margin-top: -10px;
				}
			}
		}
	}
	@media all and (max-width: 450px) {
		padding: 4rem 0;
		.copy {
			width: 80%;
		}
		.people {
			margin: 1rem auto 0;
			.person {
				width: 100%;
			}
		}
	}
`;

const AboutTeam = ({ data }) => {
	return (
		<StyledAboutTeam>
			<h2>{data.heading}</h2>
			<p className="copy">{data.teamCopy}</p>
			<div className="people">
				<div className="person">
					<img src={data.tyler.img} alt={data.tyler.name} />
					<p className="name">{data.tyler.name}</p>
					<p>{data.tyler.title}</p>
				</div>
				<div className="person">
					<img src={data.jacob.img} alt={data.jacob.name} />
					<p className="name">{data.jacob.name}</p>
					<p>{data.jacob.title}</p>
				</div>
			</div>
		</StyledAboutTeam>
	);
};

export default AboutTeam;
